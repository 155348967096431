<template>
    <KCourse loader-class="MBcont" course-id="8" title="Будуарная кукла" :items="items">
        <div class="MBtextcont">
            <h3>1. Приклеиваем ресницы, покрываем лаком глаза и губы</h3>
        </div>
                
        <VideoView video-id="3b4599adf9c34b20bf9d92652f96b8c6"/>

        <div class="MBtextcont">
            <h3>2. Завиваем кудряшки</h3>
        </div>

        <div class="container">
            <iframe class="video" src="https://www.youtube.com/embed/7jBAss2D7NQ" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
         </div>

        <div class="MBtextcont">
            <h3>3. Оформляем прическу</h3>
        </div>

        <VideoView video-id="b005e1c3e7ca41c7824d88a51fece143"/>
        <VideoView video-id="81cec4fc8e70411c95bf240ef0ae5b8d"/>

        <div class="MBtextcont">
            <h3 class="nomargin">Итого, что нужно сделать:</h3>
            <ol>
                <li>Залакировать глазки и губки</li>
                <li>Приклеить реснички</li>
                <li>Оформить прическу</li>
            </ol>

           <h3>Время выполнения: 2 часа</h3>

        <div class="summary"></div>

           <h3>Как получить сертификат?</h3>
           <p>Чтобы получить сертификат вам нужно: </p>
           <ol>
               <li>Выложить фото вашей куколки у себя на странице (с платьем или без) в любой соцсети ВКонтакте или Инстаграм</li>
               <li>Упоминуть меня @kibanovadolls</li>
               <li>Прислать мне ссылку на пост в ЛС, а так же указать, какой вид сертификата вы хотите получить бумажный / электронный. Если бумажный, то укажите ваш почтовый адрес.</li>
           </ol>
            <p>Если я поставлю лайк или скорее всего напишу комментарий, то ваша работа будет считаться принятой и вы обязательно получите сертификат.</p>
            <p>Хочу уточнить <b>сроки сдачи работ:</b></p>
            <ul>
                <li>Сопровождение куратора, если он у вас есть, продлится до конца апреля.</li>
                <li>Работы для сертификата принимаю до конца мая )</li>
            </ul>
            <p> Так же готовый результат можно выкладывать в наш чат, чтобы вдохновить участников и похвастаться результатом, а я уверенна такими достижениями обязательно нужно делиться )))</p>
        </div>
    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";
    import chapters from './data.js';
    import KImage from "../../Widgets/KImage";

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
            KImage
        },
        data() {
        return {
            items: chapters
        }
        },
        methods: {
            showImage(src, customClass) {
                this.$swal.fire({
                    customClass: customClass,
                    background: '#ffffff',
                    html: '<img width="100%" src="'+src+'"/>',
                    showCloseButton: true,
                    showConfirmButton: false,
                    // closeButtonHtml: "",
                })
            },
        }
    }
</script>

<style>

</style>